require.config({
    waitSeconds: 90,
    shim: {
        'angular': {
            exports: 'angular',
            deps: [
                'jquery'
            ]
        },
        'angular-animate': [
            'angular'
        ],
        'angular-aria': [
            'angular'
        ],
        'angular-bootstrap': [
            'angular'
        ],
        'angular-clipboard': [
            'angular'
        ],
        'angular-file-upload': [
            'angular'
        ],
        'angular-loading-bar': [
            'angular'
        ],
        'angular-permission': {
            deps: [
                'angular',
                'angular-ui-router'
            ]
        },
        'angular-permission-ui': {
            deps: [
                'angular',
                'angular-ui-router',
                'angular-permission'
            ]
        },
        'angular-sanitize': [
            'angular'
        ],
        'angular-toaster': {
            deps: [
                'angular',
                'angular-animate'
            ]
        },
        'angular-ui-router': {
            deps: [
                'angular'
            ]
        },
        'angular-upload': [
            'angular'
        ],
        'chartjs': [],
        'file-saver': [],
        'jquery-date-range-picker': [
            'jquery',
            'moment'
        ],
        'jquery-nice-select': [
            'jquery'
        ],
        'jquery.maskedinput': [
            'jquery'
        ],
        'less': [
            'jquery'
        ],
        'lodash': {
            exports: '_'
        },
        'moment': [],
        'ng-dialog': [
            'angular'
        ],
        'seiyria-bootstrap-slider': [
            'jquery'
        ]
    },
    paths: {
        'almond': '../../lib/almond/almond',
        'angular': '../../lib/angular/angular.min',
        'angular-animate': '../../lib/angular-animate/angular-animate.min',
        'angular-aria': '../../lib/angular-aria/angular-aria.min',
        'angular-bootstrap': '../../lib/angular-bootstrap/ui-bootstrap-tpls.min',
        'angular-clipboard': '../../lib/angular-clipboard/angular-clipboard',
        'angular-file-upload': '../../lib/angular-file-upload/dist/angular-file-upload.min',
        'angular-loading-bar': '../../lib/angular-loading-bar/build/loading-bar.min',
        'angular-permission': '../../lib/angular-permission/dist/angular-permission',
        'angular-permission-ui': '../../lib/angular-permission/dist/angular-permission-ui',
        'angular-sanitize': '../../lib/angular-sanitize/angular-sanitize.min',
        'angular-toaster': '../../lib/AngularJS-Toaster/toaster',
        'angular-ui-router': '../../lib/angular-ui-router/release/angular-ui-router.min',
        'angular-upload': '../../lib/angular-upload/angular-upload',
        'chartjs': '../../lib/chart.js/dist/Chart.bundle.min',
        'file-saver': '../../lib/file-saver/FileSaver',
        'jquery': '../../lib/jquery/dist/jquery.min',
        'jquery-date-range-picker': '../../lib/jquery-date-range-picker/dist/jquery.daterangepicker.min',
        'jquery-nice-select': '../../lib/jquery-nice-select/js/jquery.nice-select.min',
        'jquery.maskedinput': '../../lib/jquery.maskedinput/dist/jquery.maskedinput.min',
        'less': '../../lib/less/dist/less.min',
        'lodash': '../../lib/lodash/dist/lodash.min',
        'moment': '../../lib/moment/min/moment.min',
        'ng-dialog': '../../lib/ng-dialog/js/ngDialog.min',
        'requirejs-domready': '../../lib/requirejs-domready/domReady',
        'seiyria-bootstrap-slider': '../../lib/seiyria-bootstrap-slider/dist/bootstrap-slider.min',
        'sprintf': '../../lib/sprintf/dist/sprintf.min'
    },
    deps: ['./bootstrap.es6']
});
